import React from "react";
import './About.css';
import axios from "axios";
import {MdDownload} from "react-icons/md";

export default function Apply() {
    const captionStyle = {
        color: 'grey',
        marginTop: '2px',
        fontSize: '95%'
    }

    const downloadFile = (url) => {
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then(response => {
            const fileUrl = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = fileUrl;

            // Extract file name from response headers or use a default name
            const contentDisposition = response.headers['content-disposition'];
            let fileName = "MCEA_APPLICATION_FORM.pdf"; // Default file name
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="?([^"]+)"?/);
                if (match && match[1]) {
                    fileName = match[1];
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(fileUrl);
        }).catch(error => {
            console.error('Error downloading the file:', error);
        });
    };



    return (
        <div className="">
            <div className="aboutBody">
                <br></br>
                <div className='contactUs'>
                    <span className="paraTitle">Apply for a programme</span>
                    <br></br>
                    <br></br>
                    <span style={captionStyle}>Please download the application form below:</span>

                    <div>
                        <button  className='downloadButton' onClick={() => { downloadFile("https://firebasestorage.googleapis.com/v0/b/mennonitecollege-ea.appspot.com/o/Application%20Form%20MCEA.pdf?alt=media&token=7283ef18-70ba-4afc-954b-2d3d6383bbe5")}} >MCEA Application Form.pdf &nbsp;<MdDownload size={35}/></button>
                        {/* <button className='deleteBtn'><IoMdClose size={20}/></button> */}
                    </div>
            
            
                </div>
            </div>
        </div>
    )
}
