import {React, useEffect, useState} from "react";
import '../App.css';
import logo from '../images/image.png';
import logo2 from '../images/logo.png';
import {Link, Route, Routes} from 'react-router-dom';
import HomePage from "./tabs/HomePage";
import Gallery from "./tabs/Gallery";
import Admission from "./tabs/Admission";
import Administration from "./tabs/Administration";
import About from "./tabs/About";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TableChartIcon from '@mui/icons-material/TableChart';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import { GrGallery } from "react-icons/gr";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { FaGraduationCap } from "react-icons/fa6";

import {
    Box, Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {initializeApp} from "firebase/app";
import {IoMdClose} from "react-icons/io";
import Apply from "./tabs/Apply";



const firebaseConfig = {
    apiKey: "AIzaSyAlEwN2wHkXi8GkUNDD0XC_isaNG-qTsjw",
    authDomain: "ieagtchurch.firebaseapp.com",
    databaseURL: "https://ieagtchurch-default-rtdb.firebaseio.com",
    projectId: "ieagtchurch",
    storageBucket: "ieagtchurch.appspot.com",
    messagingSenderId: "423779868538",
    appId: "1:423779868538:web:234107a803b6cee439c0c3"
  };

  const footerStyle = {
    backgroundColor: '#180f0f',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    textAlign: "center",
    // position: 'fixed',
    bottom: "0",
    color: 'lightgray',
    fontSize: "90%",
    width: "100%",
    justifyContent: 'center',
    clear: "both",
    marginTop: "auto",
    height: "100px"
}

initializeApp(firebaseConfig);

export default function Home() {
    const titles = [
        "Home", "Admission", "Gallery", "Administration & Management", "About MCEA"
    ];

    const [activeTab, setActiveTab] = useState(0);
    const [tabTitle, setTabTitle] = useState(titles[activeTab]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isOffering, setIsOffering] = useState(false);

    const menuIcons = [
        <HomeIcon color='white'/>, <MenuBookIcon color='white'/>, <GrGallery color='white'/>, <TableChartIcon color='white'/>, <InfoIcon color='white'/>
    ];

    useEffect(() => {
        window.onscroll = function() {
            fixHeader();
        }
    }, []);

    const paths = [
        '/', 'admission', 'gallery', 'administration', 'about'
    ]

    const navItemStyle = {
        color: 'white'
    };


    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={() => {
            setDrawerOpen(false);
        }}>
            <List>
                {['Home', 'Admission', 'Gallery', 'Administration', 'About us'].map((text, index) => {

                        return (
                            <Link  key={index} style={navItemStyle} to={paths[index]}>
                                <ListItem key={text} disablePadding>
                                    <ListItemButton onClick={() => {
                                        setActiveTab(index);
                                        setTabTitle(titles[index]);
                                    }}>
                                        <ListItemIcon>
                                            {menuIcons[index]}
                                        </ListItemIcon>
                                        <ListItemText primary={text}/>
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        )
                    }
                )}
            </List>
        </Box>
    );

    function fixHeader() {
        var header = document.getElementById('navbar');
        var mobileHeader = document.getElementById('mobileTabs');
        var sticky = header.offsetTop;

        if (window.scrollY > sticky) {
            header.classList.add("sticky");
            mobileHeader.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
            mobileHeader.classList.remove("sticky");
        }
    }


    return (
        <div>
            <div className='navBar' id='navbar'>
                <div className='actionBar'>
                    <img src={logo} alt="" width='25'/>

                    <div className="tabs">
                        <Link to='/'>
                            <div className={"tabItem" + (activeTab === 0 ? " activeTab" : "")}
                                 onClick={() => setActiveTab(0)}>
                                Home
                            </div>
                        </Link>
                        <Link to='admission'>
                            <div className={"tabItem" + (activeTab === 1 ? " activeTab" : "")}
                                 onClick={() => setActiveTab(1)}>
                                Admission
                            </div>
                        </Link>
                        <Link to='gallery'>
                            <div className={"tabItem" + (activeTab === 2 ? " activeTab" : "")}
                                 onClick={() => setActiveTab(2)}>
                                Gallery
                            </div>
                        </Link>
                        <Link to='administration'>
                            <div className={"tabItem" + (activeTab === 3 ? " activeTab" : "")}
                                 onClick={() => setActiveTab(3)}>
                                Administration
                            </div>
                        </Link>
                        <Link to='about'>
                            <div className={"tabItem" + (activeTab === 4 ? " activeTab" : "")}
                                 onClick={() => setActiveTab(4)}>
                                About us
                            </div>
                        </Link>

                    </div>

                    <Link to='apply'><Button className="offeringBtn" onClick={() => setActiveTab(5)} variant="contained"
                                             color="primary" size="small">APPLY</Button></Link>
                </div>
            </div>

            {/* // MOBILE TAB */}
            <div className='mobileTabs' id='mobileTabs'>
                <div className='drawerController'>
                    <IconButton aria-label="delete" onClick={() => {
                        setDrawerOpen(true);
                    }}>
                        <MenuIcon color="white"/>
                    </IconButton>
                    <div className='tabTitle'>{tabTitle}</div>
                </div>

                <img src={logo} alt="" width='30'/>

                <Link to='apply'><Button className="offeringBtn" onClick={() => setActiveTab(5)} variant="contained"
                                         color="primary" size="small">APPLY</Button></Link>
            </div>

            <div className="header">
                <div className="headerRow">
                    {activeTab === 0 ? <img src={logo} alt="" width='110'/> : <div></div>}
                    <span className="topText">
                        {activeTab === 0 ? <span>Mennonite College of<br></br>Eastern Africa (MCEA)</span> : titles[activeTab]}
                    </span>
                </div>

            </div>

            {/* <div className="redStrip"></div> */}


            {/*<div className="horizontalDivider"></div>*/}


            <div className="mainContainer">
                <Routes>
                    <Route path="/" Component={HomePage}/>
                    <Route path="/admission" Component={Admission}/>
                    <Route path="/gallery" Component={Gallery}/>
                    <Route path="/administration" Component={Administration}/>
                    <Route path="/about" Component={About}/>
                    <Route path="/apply" Component={Apply}/>
                    <Route index Component={HomePage}/>
                </Routes>
            </div>


            {/* OFFERING MODAL */}
            {isOffering ? <div className='modalOverlay'>
                <div className='modalBox'>
                    <div className='modalHeader'>
                        <h2 className='modalTitle'>&nbsp; Send your Offering (or Donation)</h2>
                        <button className='closeModalBtn' onClick={() => {
                            setIsOffering(false)
                        }}><IoMdClose size={25}/></button>
                    </div>

                    <div className='horizontalDivider'></div>
                    <div className='modalForm'>

                        <span className='hint'>Hamisha fedha kutoka kwenye akaunti yako ya Wito kwenda kwenye akaunti nyingine.</span>

                    </div>
                </div>
            </div> : <></>}


            <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <div className="drawer">
                    {/* <br></br> */}
                    {/* <img src={logo} alt="" width='100'/> */}
                    {/* <br></br> */}
                    {DrawerList}
                </div>
            </Drawer>

        </div>
    )
}